<template lang="pug">
.listgames
  //- Listado de categorías
  GroupCategories(:categories="categories")
  
  //- Listado de juegos de un proveedor
  v-container
    //- h2 {{ translations.title }}
    h2.listgames__title(v-if="provider")
      v-img.listgames__img(
        :src="provider.image",
        :alt="provider.name")
      span {{ provider.name }}
    .listgames__group
      .listgames__item(
        v-for="game in gamesToShow"
        :key="game.id")
        CardGame(
          :title="game.name",
          :subtitle="game.type",
          :image="game.image"
          :gameId="game.vg"
          :fun="game.fun")
    div(v-intersect="onIntersect")
</template>

<script>
import { mapState } from "vuex"
import CardGame from "@/components/CardGame"

import GamesServices from "@/services/GamesServices"
import GroupCategories from "@/components/GroupCategories"

export default {
  name: "Provider",
  components: {
    CardGame,
    GroupCategories,
  },
  data: () => ({
    games: [],
    items: 1,
    itemsPerPage: 20,
    showing: false,
  }),
  computed: {
    ...mapState(['lobbyId', 'provider', 'categories']),
    translations() {
      return this.$t('views.Provider')
    },
    gamesToShow() {
      return this.games.slice(0, this.items)
    }
  },
  created() {
    if(this.provider) {
      GamesServices.getGamesByProvider(this.lobbyId, this.provider.id).then(games => {
        this.games = [...games]
      })
    } else {
      this.$router.push({name:'Home', params: { lobbyId: this.lobbyId } })
    }
  },
  methods: {
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.items += this.itemsPerPage
      }
    },
  },
};
</script>

<style lang="sass">
.listgames
  &__title
    display: flex
    align-items: center
  &__img
    flex: 0 0 auto
    height: 28px
    width: 28px
    margin-right: 12px
</style>
